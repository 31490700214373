<template>
  <div v-if="roots" class="node-children">
    <NavigationTreeNode
      v-for="root in roots"
      :key="root.to || root"
      :node="root"
      :locale-section="localeSection"
    />
  </div>
</template>

<script>
export default {
  name: "NavigationTree",
  components: {
    NavigationTreeNode: () => import("@/components/NavigationTreeNode")
  },
  props: {
    roots: {
      type: Array,
      default: () => []
    },
    localeSection: {
      type: String,
      required: true
    }
  }
};
</script>
